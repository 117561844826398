// MainRoutes.js
import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import IndexThree from './pages/index-three'
const AboutUs = lazy(() => import('./pages/aboutus'));
const Pricing = lazy(() => import('./pages/pricing'));
const TrialPlanPricing = lazy(() => import('./pages/TrialPlanPricing'));
const PaymentFailure = lazy(() => import('../src/components/PaymentFailure'));
const PaymentSuccess = lazy(() => import('../src/components/PaymentSuccess'));
const PendingPaymentPage = lazy(() => import('../src/components/PendingPaymentPage'));
const Institution = lazy(() => import('./pages/institution'));
const SignupLite = lazy(() => import('./pages/signuplite'));
const Contact = lazy(() => import('./pages/contact'));

function MainRoutes() {
  return (
    <Routes>
      <Route path='/' element={ <IndexThree /> } />
      <Route index path='/aboutus' element={<Suspense fallback={<>...</>}> <AboutUs /> </Suspense>} />
      <Route path='/Plan' element={<Suspense fallback={<>...</>}> <Pricing /> </Suspense>} />
      <Route path='/trialplan' element={<Suspense fallback={<>...</>}> <TrialPlanPricing /> </Suspense>} />
      <Route index path='/PaymentFailure' element={<Suspense fallback={<>...</>}> <PaymentFailure /> </Suspense>} />
      <Route index path='/PaymentSuccess' element={<Suspense fallback={<>...</>}> <PaymentSuccess /> </Suspense>} />
      <Route index path='/PendingPaymentPage' element={<Suspense fallback={<>...</>}> <PendingPaymentPage /> </Suspense>} />
      <Route index path='/contact' element={<Suspense fallback={<>...</>}> <Contact /> </Suspense>} />
      <Route index path='/institution' element={<Suspense fallback={<>...</>}> <Institution /> </Suspense>} />
      <Route index path='/institution/signup' element={<Suspense fallback={<>...</>}> <SignupLite /> </Suspense>} />
    </Routes>
  );
}

export default MainRoutes;
